<template>
    <div class="masterMaterialPrize-child">
        <div class="masterMaterialPrize-box-child">
            <h1 class="day-trend-head">
                <span></span>
                <span>按原材料主材价格趋势下钻</span>
                <span class="iconfont" @click="closeClueStatistics">&#xe67d;</span>
            </h1>
            <div style="padding:.2rem">
                <div class="masterMaterialPrize-one">
                    <h1>数量/价格统计图</h1>
                    <div style="width:100%;height:240px;" id="masterMaterialPrizeId"></div>
                </div>
                <div class="masterMaterialPrize-two">
                    <el-table
                        :data="tableData"
                        border
                        style="width: 100%"
                    >
                        <el-table-column
                            :label="cailiaoName.name"
                            prop="label"
                        >
                        </el-table-column>
                        <el-table-column
                            v-for="(item, index) in aaaaaaaaaaaaa"
                            :key="index"
                            :label="item"
                            prop="lebel"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.label === '价格'">
                                    {{ scope.row['jg' + index] || 0.00 }}
                                </div>
                                <div v-if="scope.row.label === '数量'">
                                    {{ scope.row['sl' + index] || 0 }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="本年均价"
                            prop="bnjj"
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import addBgImages from '../../../utils';
export default {
    data() {
        return {
            aaaaaaaaaaaaa: [],
            tableData: [
                { label: '价格', bnjj: 0.00 },
                { label: '数量', bnjj: '-' },
            ],
            tableName: [],
            tableJg: [],
            tableSl: [],
        };
    },
    props: {
        cailiaoName: {
            type: Object,
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.salesanalysisYwy();
        });
    },
    methods: {
        closeClueStatistics() {
            this.$emit('closeChildAlert');
        },
        clueStatisticsChildFun() {
            const myChart = this.$echarts.init(document.getElementById('masterMaterialPrizeId'));
            const option = {
                grid: {
                    top: '18%',
                    left: '4%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    data: ['数量', '价格'],
                    x: 'right',
                    padding: [20, 50, 0, 0],
                },
                xAxis: {
                    data: this.tableName,
                    type: 'category',
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: { show: false },
                },
                yAxis: [
                    {
                        type: 'value',
                        nameTextStyle: {
                            padding: [0, 0, -20, 50], // 四个数字分别为上右下左与原位置距离
                            color: '#979EA7',
                            fontSize: '10',
                        },
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                                type: 'dashed',
                            },
                        },
                    },
                    {
                        type: 'value',
                        nameTextStyle: {
                            padding: [0, 0, -20, 50], // 四个数字分别为上右下左与原位置距离
                            color: '#979EA7',
                            fontSize: '10',
                        },
                        axisLabel: {
                            color: '#666666',
                            fontSize: 10,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#D7D7D7',
                            },
                        },
                        splitLine: { show: false },
                    },
                ],
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        data: this.tableSl,
                        barWidth: 30,
                        color: '#44A1F5',
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'QuartzLTStd',
                            padding: 5,
                            color: '#4D7DE0',
                            // backgroundColor: {
                            //     image: addBgImages(1, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#3E78C3', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#44A1F5', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                    {
                        name: '价格',
                        type: 'line',
                        yAxisIndex: 1,
                        data: this.tableJg,
                        label: {
                            fontFamily: 'QuartzLTStd',
                            show: true,
                            position: 'top',
                            fontSize: '14',
                            padding: 5,
                            // backgroundColor: {
                            //     image: addBgImages(2, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: '#73DABE',
                            },
                        },
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        salesanalysisYwy() {
            this.$axios
                .get(`/interfaceApi/report/materialanalysis/yclcg/ylzcjgqs/${this.cailiaoName.code}`)
                .then(res => {
                    this.aaaaaaaaaaaaa = res.date;
                    let jj = 0.00;
                    res.list.forEach((item, index) => {
                        this.tableData[0]['jg' + index] = Number(item.jg).toFixed(2);
                        this.tableData[1]['sl' + index] = item.sl;
                        jj = Math.round(jj * 100) + Math.round(item.jg);
                        this.tableName.push(item.date);
                        this.tableJg.push(item.jg);
                        this.tableSl.push(item.sl);
                    });
                    // 计算本年度均价
                    jj = (jj / res.list.length / 100).toFixed(2);
                    this.tableData[0].bnjj = jj;
                    this.clueStatisticsChildFun();
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.masterMaterialPrize-child
    height 100%
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    z-index 99
    display flex
    justify-content center
    align-items center
    .masterMaterialPrize-box-child
        width 90%
        background #fff
        .day-trend-head
            width 100%
            background #0C2C87
            text-align center
            color #fff
            padding .1rem 0;
            display flex
            justify-content space-between
            align-items center
            span
                font-size .2rem
                &:last-child
                    margin-right .2rem
                    cursor pointer
        .masterMaterialPrize-one
            display flex
            flex-direction column
            justify-content space-between
            border 1px solid #D7D7D7
            h1
                color #333333
                font-size .18rem
                font-weight 800
                padding .2rem
                &:before
                    content '`'
                    width .06rem
                    height .2rem
                    background #4D7DE0
                    color #4D7DE0
                    margin-right .1rem
        .masterMaterialPrize-two
            margin-top .2rem
            .el-table
                width 100%
                .el-table__footer-wrapper tbody tr td div
                    color #C9661E !important
                .el-table__header-wrapper thead tr th
                    background #EDF0F5
                    color #022782
                th, td
                        padding .05rem 0
                        text-align center
                        div
                            font-size .16rem
                    .el-table__footer tbody tr
                        td
                            background none
                            div
                                color #823802
                        td:first-child
                            background #F5F2ED
                            div
                                font-weight 800
</style>