<template>
    <div class="classifiedDrilling">
        <div class="classifiedDrilling-child">
            <h1 class="day-trend-head">
                <span></span>
                <span>按原材料分类下钻</span>
                <span class="iconfont" @click="closeClueStatistics">&#xe67d;</span>
            </h1>
            <div class="flex-box-child">
                <div class="clue-statistics-one">
                    <div class="clue-statistics-childs">
                        <h1>原材料采购数量</h1>
                        <div ref="echartsOne" style="width:100%;height:240px;" id="clueStatisticsChildId"></div>
                    </div>
                    <div class="clue-statistics-childs zcje-money">
                        <h1>主材金额</h1>
                        <div class="echarts-position-div" :style="{color: selectColorChild}">
                            <p>
                                {{ salesVolumeTextSubText }}%
                            </p>
                            <p>{{ salesVolumeText }}</p>
                        </div>
                        <div ref="echartsTwo" style="width:100%;height:240px" id="childSalesVolume"></div>
                    </div>
                </div>
                <div class="clue-statistics-two">
                    <el-table
                        :data="tableData"
                        border
                        style="width: 100%;"
                        max-height="300"
                        show-summary
                    >
                        <el-table-column
                            prop="name"
                            label="原材料分类名称"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="cgsl"
                            label="采购数量"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="gbcc"
                            label="过磅车次"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="zcje"
                            label="主材金额"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="zcdj"
                            label="主材单价"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="yf"
                            label="运费"
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>

        <masterMaterialPrize v-if="showMateria" :cailiao-name="cailiaoName" @closeChildAlert="closeChildFn"></masterMaterialPrize>
    </div>
</template>

<script>
// import addBgImages from '../../../utils';
import masterMaterialPrize from './masterMaterialPrize';
export default {
    data() {
        return {
            tableData: [],
            tableName: [],
            tableCgsl: [],
            tableCgs: [],

            showMateria: false,
            cailiaoName: {},
            newClickName: '',
            flTotalNumber: 0,
            salesVolumeText: '',
            salesVolumeTextSubText: '',
            selectColorChild: '',
            salesVolumeData: [],
            echartsColor: ['#EA8343', '#4CA295', '#4BA2DA', '#3E78C3', '#f2719a', '#fca4bb', '#f59a8f', '#fdb301', '#57e7ec', '#cf9ef1'],
        };
    },
    props: {
        clickName: {
            type: String,
        },
    },
    components: {
        masterMaterialPrize,
    },
    mounted() {
        this.$nextTick(async () => {
            this.newClickName = this.heckAllCnText(this.clickName);
            await this.salesanalysisYwy();
        });
    },
    methods: {
        heckAllCnText(str) {
            const reg = /[\u4E00-\u9FA5]/g;
            if (reg.test(str)) {
                const aaa = str.replace(reg, '-');
                return aaa.slice(0, aaa.length - 1);
            }
            return str;
        },
        closeChildFn() {
            this.showMateria = false;
        },
        closeClueStatistics() {
            this.$emit('closeChildAlert');
        },
        clueStatisticsChildFun() {
            const myChart = this.$echarts.init(document.getElementById('clueStatisticsChildId'));
            const option = {
                grid: {
                    top: '18%',
                    left: '4%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    padding: [20, 50, 0, 0],
                },
                xAxis: {
                    data: this.tableName,
                    type: 'category',
                    triggerEvent: true,
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                        interval: 0,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: { show: false },
                },
                yAxis: {
                    nameTextStyle: {
                        padding: [0, 0, -20, 50], // 四个数字分别为上右下左与原位置距离
                        color: '#979EA7',
                        fontSize: '10',
                    },
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                            type: 'dashed',
                        },
                    },
                },
                series: [
                    {
                        data: this.tableCgsl,
                        type: 'bar',
                        barWidth: 30,
                        color: '#44A1F5',
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'QuartzLTStd',
                            padding: 5,
                            color: '#4D7DE0',
                            // backgroundColor: {
                            //     image: addBgImages(1, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#3E78C3', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#44A1F5', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                ],
            };
            const that = this;
            myChart.on('click', function (params) {
                if (params.componentType === 'xAxis') {
                    that.showClassification = true;
                } else {
                    that.cailiaoName = params.seriesName;
                    that.showMateria = true;
                }
            });
            myChart.setOption(option, true);
        },
        // 各类合同销售方量统计
        salesVolumeEcharts() {
            const that = this;
            const myChart = this.$echarts.init(document.getElementById('childSalesVolume'));
            const option = {
                color: that.echartsColor,
                legend: {
                    right: 60,
                    top: 50,
                    itemWidth: 8,
                    itemHeight: 8,
                    width: '300',
                },
                series: [{
                    type: 'pie',
                    minAngle: 1, // 最小的扇区角度（0 ~ 360）
                    radius: ['40%', '70%'],
                    center: ['20%', '40%'],
                    avoidLabelOverlap: false,
                    itemStyle: { // 图形样式
                        normal: {
                            borderColor: '#ffffff',
                            borderWidth: 6,
                        },
                    },
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            formatter: '{text|{b}}\n{c} ({d}%)',
                            rich: {
                                text: {
                                    color: '#666',
                                    fontSize: 14,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    padding: 8,
                                },
                                value: {
                                    color: '#8693F3',
                                    fontSize: 24,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                },
                            },
                        },
                    },
                    data: that.salesVolumeData,
                }],
            };
            myChart.setOption(option);

            setTimeout(function () {
                myChart.on('click', function (params) {
                    that.salesVolumeData.forEach(item => {
                        if (params.name === item.name) {
                            that.cailiaoName = {
                                code: item.code,
                                name: item.name,
                            };
                            that.showMateria = true;
                        }
                    });

                });
                myChart.on('mouseover', function (params) {
                    if (params.name === that.salesVolumeData[0].name) {
                        myChart.dispatchAction({
                            type: 'highlight',
                            seriesIndex: 0,
                            dataIndex: 0,
                        });
                        that.salesVolumeText = params.name;
                        that.salesVolumeTextSubText = params.percent;
                    } else {
                        myChart.dispatchAction({
                            type: 'downplay',
                            seriesIndex: 0,
                            dataIndex: 0,
                        });
                        that.salesVolumeText = params.name;
                        that.salesVolumeTextSubText = params.percent;
                    }
                    that.selectColorChild = params.color;
                });

            }, 1000);
        },
        async salesanalysisYwy() {
            await this.$axios
                .get(`/interfaceApi/report/materialanalysis/yclcg/ylfl/month?date=${this.newClickName}`)
                .then(res => {
                    this.tableData = res.list;
                    this.tableData.forEach(item => {
                        this.flTotalNumber += item.cgsl;
                        this.tableName.push(item.name);
                        this.tableCgsl.push(item.cgsl);
                        const circularData = {
                            name: item.name,
                            value: item.zcje,
                            code: item.code,
                        };
                        this.salesVolumeData.push(circularData);
                    });
                    // eslint-disable-next-line max-len
                    const oneDataNumber = isNaN(this.salesVolumeData[0].value / this.flTotalNumber * 100) ? 0 : this.salesVolumeData[0].value / this.flTotalNumber * 100;
                    this.salesVolumeText = this.salesVolumeData[0].name;
                    this.salesVolumeTextSubText = oneDataNumber.toFixed(1);
                    this.selectColorChild = this.echartsColor[0];
                    this.clueStatisticsChildFun();
                    this.salesVolumeEcharts();
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.classifiedDrilling
    height 100%
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    z-index 99
    display flex
    justify-content center
    align-items center
    .classifiedDrilling-child
        width 90%
        min-height 85%
        background #fff
        position relative
        .day-trend-head
            width 100%
            background #0C2C87
            text-align center
            color #fff
            padding .1rem 0;
            display flex
            justify-content space-between
            align-items center
            span
                font-size .2rem
                &:last-child
                    margin-right .2rem
                    cursor pointer
        .flex-box-child
            display flex
            flex-direction column
            height 100%
            padding:.2rem
            box-sizing border-box
            .clue-statistics-one
                display flex
                justify-content space-between
                h1
                    color #333333
                    font-size .18rem
                    font-weight 800
                    padding .2rem
                    &:before
                        content '`'
                        width .06rem
                        height .2rem
                        background #4D7DE0
                        color #4D7DE0
                        margin-right .1rem
                .clue-statistics-childs
                    flex 1
                    border 1px solid #D7D7D7
                    box-shadow: .02rem .01rem .06rem 0px rgba(85,136,241,0.6);
                    &:last-child
                        margin-left .2rem
                .zcje-money
                    position relative
                    .echarts-position-div
                        position: absolute;
                        top: 1.45rem;
                        left: 1.05rem;
                        z-index: 9;
                        text-align center
                        font-family QuartzLTStd
                        width 1.3rem
            .clue-statistics-two
                margin-top .2rem
                // padding-bottom .4rem
                display flex
                flex-direction column
                .el-table
                    flex 1
                    width 100%
                    overflow:visible !important;
                    // overflow auto
                    .el-table__footer-wrapper tbody tr td div
                        color #C9661E !important
                    .el-table__header-wrapper thead tr th
                        background #EDF0F5
                        color #022782
                    th, td
                            padding .05rem 0
                            text-align center
                            div
                                font-size .16rem
                        .el-table__footer tbody tr
                            td
                                background none
                                div
                                    color #823802
                            td:first-child
                                background #F5F2ED
                                div
                                    font-weight 800
</style>